
window.alert = alert;

function alert(data) {
  var a = document.createElement("div"),
    p = document.createElement("p"),
    btn = document.createElement("div"),
    textNode = document.createTextNode(data ? data : ""),
    btnText = document.createTextNode("确定"),
    // 脸
    face = document.createElement("div"),
    eye = document.createElement("div"),
    eyeRight = document.createElement("div"),
    mouthHappy = document.createElement("div"),
    // 脸下阴影
    shadow = document.createElement("div");
  // 样式

  if (data === '奖励已到账，请下载APP领取' || data === '奖励已到账，请去小程序查看') {
    css(a, {
      "position": "fixed",
      "left": "0",
      "right": "0",
      "top": "20%",
      "width": "209px",
      "margin": "0 auto",
      "background": "#fff",
      "font-size": "16px",
      "text-align": "center",
      "border-radius": "20px",
      "padding": "40px 13px 13px",
      "box-shadow": '5px 5px 20px rgba($gray, 10%)',
      "perspective": "40px",
      "border": '1px solid #6e34ce',
    });
    css(face, {
      "width": "44px",
      "height": "44px",
      "background": "#FCFCFC",
      "border-radius": "50%",
      "border": "1px solid #6e34ce",
      "z-index": "2",
      "-webkit-animation": 'bounce 1s ease-in infinite',
      "animation": "bounce 1s ease-in infinite",
      "margin": "0 auto",
    })

    css(mouthHappy, {
      "width": "7px",
      "height": "7px",
      "border-radius": "50%",
      "border": "2px solid",
      "border-color": "transparent #6e34ce #6e34ce transparent",
      "transform": "rotate(45deg)",
      "position": 'absolute',
      "top": "26%",
      "left": "40%",
    })

    css(shadow, {
      "position": "relative",
      "width": "42px",
      "height": "6px",
      "opacity": ".5",
      "background": '#e2d6f5',
      "border-radius": '50%',
      "z-index": "1",
      "-webkit-animation": "scale 1s ease-in infinite",
      "animation": "scale 1s ease-in infinite",
      "top": "8px",
      "margin": "0 auto",
    })
    css(btn, {
      "width": "50%",
      "height": "15%",
      "border-radius": "20px",
      "outline": "0",
      "border": "1px solid #6e34ce",
      "box-shadow": "2px 2px 10px rgba(#777777, .5)",
      "transition": "all .5s ease-in-out",
      "margin": "0 auto",
      "color": "#fff",
      "padding": "5px",
    })

    css(btn, {
      "background": "#6e34ce",
      "border-radius": "10px",
    })

  } else {
    css(a, {
      "position": "fixed",
      "left": "0",
      "right": "0",
      "top": "20%",
      "width": "209px",
      "margin": "0 auto",
      "background": "#fff",
      "font-size": "16px",
      "text-align": "center",
      "border-radius": "20px",
      "padding": "80px 13px 13px 13px",
      "box-shadow": '5px 5px 20px rgba($gray, 10%)',
      "perspective": "40px",
      "border": '1px solid #6e34ce',
    });

    css(face, {
      "width": "44px",
      "height": "44px",
      "background": "#FCFCFC",
      "border-radius": "50%",
      "border": "1px solid #6e34ce",
      "z-index": "2",
      "-webkit-animation": 'roll 3s ease-in-out infinite',
      "animation": "roll 3s ease-in-out infinite",
      "margin": "0 auto",
      "position": "absolute",
      "top": "12%",
    })

    css(mouthHappy, {
      "width": "7px",
      "height": "7px",
      "border-radius": "50%",
      "border": "2px solid",
      "border-color": "#6e34ce transparent  transparent #6e34ce",
      "transform": "rotate(45deg)",
      "position": 'absolute',
      "top": "38%",
      "left": "40%",
    })

    css(shadow, {
      "position": "absolute",
      "width": "42px",
      "height": "6px",
      "opacity": ".5",
      "background": '#e2d6f5',
      "border-radius": '50%',
      "z-index": "1",
      "-webkit-animation": "move 3s ease-in-out infinite",
      "animation": "move 3s ease-in-out infinite",
      "margin": "0 auto",
      "left": "21%",
      "top": "45%",
    })

    css(btn, {
      "width": "50%",
      "height": "15%",
      "border-radius": "20px",
      "outline": "0",
      "border": "none",
      "box-shadow": "2px 2px 10px rgba(#777777, .5)",
      "transition": "all .5s ease-in-out",
      "margin": "0 auto",
      "color": "#fff",
      "padding": "5px",
    })

    css(btn, {
      "background": "#f65d7c",
      "border-radius": "10px",
    })

  }


  css(eye, {
    "width": '5px',
    "height": "5px",
    "background": "#6e34ce",
    "border-radius": "50%",
    "position": 'absolute',
    "top": "33%",
    "left": "22%",
  })

  css(eyeRight, {
    "width": '5px',
    "height": "5px",
    "background": "#6e34ce",
    "border-radius": "50%",
    "position": 'absolute',
    "top": "33%",
    "left": "71%",
  })


  css(p, {
    "font-size": "0.9em",
    "font-weight": "100",
    "letter-spacing": '1px',
    "padding-top": '5px',
    "color": '#404040',
    "padding-bottom": "5px",
    "text-transform": "uppercase",
  })


  // 内部结构套入
  p.appendChild(textNode);
  btn.appendChild(btnText);
  face.append(eye);
  face.append(eyeRight);
  face.append(mouthHappy);
  a.append(face);
  a.append(shadow);
  a.appendChild(p);
  a.appendChild(btn);
  // 样式


  // 整体显示到页面内
  document.getElementsByTagName("body")[0].appendChild(a);

  // 确定绑定点击事件删除标签
  if(window.__wxjs_environment === 'miniprogram') {
    // 小程序
    btn.onclick = function () {
      a.parentNode.removeChild(a);
      wx.miniProgram.redirectTo({url: '/pages/mine/index'})
    }
  } else  {
    btn.onclick = function () {
      a.parentNode.removeChild(a);
      // window.location.href = "https://sj.qq.com/myapp/detail.htm?apkName=com.yuhu.tech.grampusmine";
      window.location.href = "https://www.jingkuang.info/download";
    }
  }
}

function css(targetObj, cssObj) {
  var str = targetObj.getAttribute("style") ? targetObj.getAttribute("style") : "";
  for (var i in cssObj) {
    str += i + ":" + cssObj[i] + ";";
  }
  targetObj.style.cssText = str;
}


window.clickButton = function () {


  var xmlhttp;
  var value = document.getElementsByClassName("contentInput")[0].value;
  var code = getUrl();
  console.log(value);
  console.log(code);
  if (window.XMLHttpRequest) {
    xmlhttp = new XMLHttpRequest();
  } else {
    xmlhttp = new ActiveXObject("Microsoft.XMLHTTP");
  }

  xmlhttp.open("POST", "./server/v1/consumer/invite_relation", true);
  // xmlhttp.open("POST", "http://enjoy.kuaixiang.tech/server/v1/consumer/invite_relation", true);
  xmlhttp.send(JSON.stringify({'phone': value, 'invite_code': code}));

  xmlhttp.onreadystatechange = function () {
    if (xmlhttp.readyState == 4) {
      if (JSON.parse(xmlhttp.responseText).error) {
        alert(JSON.parse(xmlhttp.responseText).message)
      } else {
        console.log()
        if (window.__wxjs_environment === 'miniprogram') {
          // 是小程序
          alert('奖励已到账，请去小程序查看');
        } else {
          alert('奖励已到账，请下载APP领取');
        }
      }
    }
  }

}

window.changeInput = function () {
  var value = document.getElementsByClassName("contentInput")[0].value;
  console.log(value)
  if (value) {
    var button = document.getElementsByClassName("action-button")[0];
    button.style.backgroundColor = '#6F34CD';
  }
  if (value === '') {
    var button2 = document.getElementsByClassName("action-button")[0];
    button2.style.backgroundColor = '#8c6ac0';
  }
}

window.focuInput = function () {
  var button = document.getElementsByClassName("action-button")[0];
  button.style.backgroundColor = '#6F34CD';
}


window.getUrl = function () {
  var url = window.location.toString();
  var arrObj = url.split("?");
  if (arrObj.length > 1) {
    var arrPara = arrObj[1].split("&");
    var arr;

    for (var i = 0; i < arrPara.length; i++) {
      arr = arrPara[i].split("=");

      if (arr != null && arr[0] == 'inviteCode') {
        return arr[1];
      }
    }
    return "";
  } else {
    return "";
  }
}

